exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-details-tsx": () => import("./../../../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-overview-tsx": () => import("./../../../src/pages/overview.tsx" /* webpackChunkName: "component---src-pages-overview-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

